import { ArrayService, CookieService } from 'ngx-unificator/services';
import { delay, distinctUntilChanged, filter, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { PlatformService } from '../../core/services/platform.service';
import { StaticContentService } from '../../core/services/static-content.service';
import { UserService } from '../../core/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserBonusesService } from '../../core/services/user/user-bonuses.service';
import { StaticPageService } from '../static-page/static-page.service';
import { LootboxService } from '../../core/services/lootbox/lootbox.service';
import { TimeService } from '../../core/services/time.service';
import { TournamentsService } from '../tournaments/tournaments.service';
import { ConfettiService } from '../../core/services/confetti.service';
import { GlobalEventsService } from '../../core/services/global-events.service';
import { LocalHistoryService } from '../../core/services/local-history.service';
import { WindowService } from '../../core/services/window.service';
import { HuntModalData, WelcomePromoModal } from './base-promo.interface';
import { GroupsService } from '../../core/services/groups.service';
import { TranslationService } from '../../core/shared/translation/translation.service';
import { ModalService } from '../../core/modal-v2/modal.service';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { GamesService } from '../../core/services/games/games.service';

export enum EventType {
  First = 'first',
  Second = 'second',
  Third = 'third'
}

export abstract class BasePromo {

  /**
   * Abstract event name for hunt(halloween, easter-eggs ...)
   */
  public abstract eventName: string;

  /**
   * Event type elements
   */
  public eventType = EventType;

  /**
   * Event collected list
   */
  public eventList: unknown[] = null;

  /**
   * Event Loaded
   */
  private _eventLoaded = null;

  /**
   * Show/hide sidebar memu link
   */
  public linkMenuEnabled: boolean;

  /**
   * Bottom link enabled
   */
  public linkBottomEnabled: boolean;

  /**
   * Promo logo
   */
  public isPromoLogo: boolean;

  /**
   * Data for hunt (text, userGroup, etc)
   */
  public huntModalData: HuntModalData;

  private _platform: PlatformService = inject(PlatformService);
  private _static: StaticContentService = inject(StaticContentService);
  private _events: GlobalEventsService = inject(GlobalEventsService);
  public cookie: CookieService = inject(CookieService);
  public group: GroupsService = inject(GroupsService);
  public cmsApi: CmsApiService = inject(CmsApiService);
  public bonuses: UserBonusesService = inject(UserBonusesService);
  public games: GamesService = inject(GamesService);
  public array: ArrayService = inject(ArrayService);
  public user: UserService = inject(UserService);
  public page: StaticPageService = inject(StaticPageService);
  public lootboxService: LootboxService = inject(LootboxService);
  public time: TimeService = inject(TimeService);
  public tournaments: TournamentsService = inject(TournamentsService);
  public confetti: ConfettiService = inject(ConfettiService);
  public local: LocalHistoryService = inject(LocalHistoryService);
  public router: Router = inject(Router);
  public route: ActivatedRoute = inject(ActivatedRoute);
  public translate: TranslationService = inject(TranslationService);
  public window: WindowService = inject(WindowService);
  public platform: PlatformService = inject(PlatformService);
  public modals: ModalService = inject(ModalService);

  constructor() {
  }

  public get eventEnable(): boolean {
    return this._eventLoaded;
  }

  public get isFirstExist(): boolean {
    return this._eventLoaded && !this.eventList.includes(this.eventType.First) && this.user.auth;
  }

  public get isSecondExist(): boolean {
    return this._eventLoaded && !this.eventList.includes(this.eventType.Second) && this.user.auth;
  }

  public get isThirdExist(): boolean {
    return this._eventLoaded && !this.eventList.includes(this.eventType.Third) && this.user.auth;
  }

  public get isUserCatchHuntGroup(): boolean {
    return this.huntModalData && this.group.isExistGroup(this.huntModalData?.BonusGroupCatch);
  }

  public initHuntEvent() {
    if (this._platform.isBrowser) {
      this._static.item({ slug: 'event-' + this.eventName }).pipe(
        tap((response: any[]) => {
          if (response && response.length) {
            this._resolveHuntEvent(response);
          }
        }),
      ).subscribe();
    }
  }

  public addEventToCollection(type: EventType) {
    if (this._platform.isBrowser) {
      this.eventList.push(type);
      this.cookie.set(this.eventName, JSON.stringify(this.eventList), 999, '/');
      if (this.eventList.length === 3) {
        this.group.addToGroup(this.huntModalData.BonusGroupCatch).subscribe();
      }
      this.openHuntModal(type);
    }
  }

  private _resolveHuntEvent(data) {
    if (data[0]) {
      this.huntModalData = data[0];
    }

    if (this.huntModalData && (!this.group.isExistGroup(this.huntModalData.BonusGroupClaimed))) {
      this.cookie.check(this.eventName) ?
        this.eventList = JSON.parse(this.cookie.get(this.eventName)) :
        this.eventList = [];
      this._eventLoaded = true;
    } else {
      this._eventLoaded = false;
    }
  }

  public initMenuLink(bottomLink = false) {
    this._static.item({ slug: this.eventName + '-side-menu' })
      .pipe(
        tap((response: any[]) => {
          this.isPromoLogo = !!(response && response.length);
          if (!bottomLink) {
            this.linkMenuEnabled = !!(response && response.length);
          } else {
            this.linkBottomEnabled = !!(response && response.length);
          }
        }),
      ).subscribe();
  }

  public initWelcomePromoModal() {
    this._static.item({ slug: this.eventName + '-welcome-modal' })
      .pipe(
        filter(response => !!response?.length),
        map(response => response[0]),
        switchMap((response) => this._checkShouldOpenWelcomeModal$(response)),
      ).subscribe();
  }

  private _checkShouldOpenWelcomeModal$(modalData: WelcomePromoModal): Observable<boolean> {
    const routesIgnore = ['403', '404', 'register', 'login', 'deposit', 'cashout', 'games', 'promo', 'play'];
    return this._events.routerNavigationEnd$
      .pipe(
        takeWhile(() => !this.cookie.check('welcome-modal-promo'), true),
        distinctUntilChanged(),
        delay(120000),
        filter(() => {
          const hasWelcomeModalCookie = this.cookie.check('welcome-modal-promo');
          const shouldOpenModal = this.user.auth && !hasWelcomeModalCookie;
          return shouldOpenModal && !routesIgnore.some(route => this.router.url.includes(route));
        }),
        switchMap(() => this.checkActivatedBonuses(modalData)),
      );
  }

  public abstract openHuntModal?(type: EventType): void;

  public abstract checkActivatedBonuses?(data: WelcomePromoModal): Observable<any>;
}
