import { Injectable } from '@angular/core';
import { CmsApiService } from '../../core/services/api/cms-api.service';
import { CmsContentMapperService } from '../../core/services/cms-content-mapper.service';
import { UpdateOnLangChange } from '../../core/shared/decorators/update-on-lang-change';
import { EMPTY, forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, filter, catchError } from 'rxjs/operators';
import { TimeService, TimeStatus } from '../../core/services/time.service';
import { SsApiService } from '../../core/services/api/ss-api.service';
import { GamesService } from '../../core/services/games/games.service';
import { CommonDataService } from '../../core/services/common-data.service';
import { UserService } from '../../core/services/user/user.service';
import { Tournament, TournamentSS } from './tournaments.interface';
import { isArray } from 'src/app/core/helpers/utils';
import { ArrayService } from 'ngx-unificator/services';
import { BreakpointsService } from '../../core/services/breakpoints.service';

@Injectable({
  providedIn: 'root'
})
export class TournamentsService {

  constructor(
    private _api: CmsApiService,
    private _mapper: CmsContentMapperService,
    private _time: TimeService,
    private _ssApi: SsApiService,
    private _games: GamesService,
    private _data: CommonDataService,
    private _user: UserService,
    private _array: ArrayService,
    private _breakpoints: BreakpointsService,
  ) {
  }

  /**
   * Get promotions list from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('tournament/list')
  list(params: object = {}): ReplaySubject<Tournament[]> {
    return this._api.tournamentList(params).pipe(
      map(response => this._mapper.mapCmsData(response.data.list, {
        id: 'id',
        slug: 'slug',
        startAt: 'startAt.date',
        publishAt: 'publishAt',
        endAt: 'endAt.date',
        identifier: 'identifier',
        gameList: 'gameList',
        isMultiTournament: 'isMultiTournament'
      })),
      switchMap(list => this._resolveMultiTournamentList(list)),
    ) as ReplaySubject<any>;
  }

  /**
   * Map additional data for multi tournaments list
   * @param tournament
   * @returns
   */
  private _resolveMultiTournamentList(tournamentList: any[]): Observable<Tournament[]> {
    return this._ssApi.tournamentsAll().pipe(
      map((tournamentSSList: any[]) => {
        return tournamentList.map(tournament => {
          tournament.activeMultiTournament = tournamentSSList
            .filter(e => e.in_progress)
            .find(e => e.frontend_identifier === String(tournament.id));
          if (tournament.isMultiTournament && tournament.activeMultiTournament) {
            tournament.startAt = this._time.toLocalDate(tournament.activeMultiTournament ?
              tournament.activeMultiTournament.start_at :
              tournament.startAt);
            tournament.endAt = this._time.toLocalDate(tournament.activeMultiTournament ?
              tournament.activeMultiTournament.end_at :
              tournament.endAt);
            tournament.status = this._resolveTournamentStatus(tournament.startAt, tournament.endAt);
            return this._mapTournamentList([tournament])[0];
          } else {
            return this._mapTournamentList([tournament])[0];
          }
        });
      })
    );
  }

  /**
   * Get promotions item from CMS and prepare for using in frontend
   *
   * @param params
   */
  @UpdateOnLangChange('tournament/item')
  item(params: object = {}): ReplaySubject<any> {
    return this._api.tournamentItem(params).pipe(
      filter(response => Boolean(response)),
      map(response => {
        if (response.statusCode === 200) {
          return this._mapper.mapCmsData(response.data ? [response.data] : [], {
            id: 'id',
            slug: 'slug',
            startAt: 'startAt.date',
            endAt: 'endAt.date',
            identifier: 'identifier',
            gameList: 'gameList',
            prevIdentifier: 'prevIdentifier',
            isMultiTournament: 'isMultiTournament'
          });
        } else {
          return [{
            statusCode: response.statusCode
          }];
        }
      }),
      switchMap((tournament: any) => {
        return tournament[0] && tournament[0].isMultiTournament ? this._resolveMultiTournament(tournament[0]) : of(tournament);
      }),
      map(tournament => this._mapTournamentList(Array.isArray(tournament) ? tournament : [tournament])),
    ) as ReplaySubject<any>;
  }


  /**
   * Map additional data for multi tournaments
   * @param tournament
   * @returns
   */
  private _resolveMultiTournament(tournament): Observable<Tournament> {
    return this._ssApi.tournamentsAll().pipe(
      map((list: any[]) => {
        tournament.activeMultiTournament = list
          .filter(e => e.in_progress)
          .find(e => e.frontend_identifier === String(tournament.id));

        tournament.prevMultiTournament$ = (tournament.activeMultiTournament && tournament.activeMultiTournament.id) ?
          this._ssApi.tournaments(String(Number(tournament.activeMultiTournament.id) - 1)).pipe(
            switchMap((res) => forkJoin([of(res), this._user.auth ? this._ssApi.playerTournamentStatus(Number(tournament.activeMultiTournament.id) - 1) : of(null)])),
            map(([tournamentData, userStatus]) => this._mapSsTournament(tournamentData, userStatus))
          ) : EMPTY;

        tournament.startAt = this._time.toLocalDate(tournament.activeMultiTournament ?
          tournament.activeMultiTournament.start_at :
          tournament.startAt);
        tournament.endAt = this._time.toLocalDate(tournament.activeMultiTournament ?
          tournament.activeMultiTournament.end_at :
          tournament.endAt);

        return tournament;
      }),
      switchMap(t => {
        return t.activeMultiTournament && t.activeMultiTournament.id ?
          forkJoin([of(t), this._user.auth ? this._ssApi.playerTournamentStatus(t.activeMultiTournament.id).pipe(catchError(() => of(null))) : of(null)]) :
          forkJoin([of(t), this._user.auth && t.identifier ? this._ssApi.playerTournamentStatus(t.identifier).pipe(catchError(() => of(null))) : of(null)]);
      }),
      map(([t, statusData]) => this._mapSsTournament({
        ...t,
        activeMultiTournament: t.activeMultiTournament ? this._mapSsTournament(t.activeMultiTournament, statusData) : null
      }, statusData))
    );
  }

  /**
   * Map additional data for tournaments
   *
   * @param list
   * @private
   */
  private _mapTournamentList(list: Array<any>): Tournament[] {
    if (!isArray(list)) {
      return null;
    }

    const groupGameSlideCount = this._breakpoints.isDesktop ? 6 : 4;

    return list.map(tournament => {
      const startAt = typeof tournament.startAt === 'string' ? this._time.toLocalDate(tournament.startAt) : tournament.startAt;
      const endAt = typeof tournament.endAt === 'string' ? this._time.toLocalDate(tournament.endAt) : tournament.endAt;
      const status = this._resolveTournamentStatus(startAt, endAt);
      const customPrizeList = tournament && tournament.CustomPrizeList && Object.values(tournament.CustomPrizeList);
      return {
        ...tournament,
        startAt: new Date(startAt),
        endAt: new Date(endAt),
        status,
        CustomPrizeList: customPrizeList && customPrizeList.length ? Object.entries(customPrizeList[0]) : null,
        data$: tournament.identifier ? this._getSSTournamentData(tournament.identifier) : of({}),
        prevTournamentData$: tournament.prevIdentifier ?
          this._getSSTournamentData(tournament.prevIdentifier) :
          of({}),
        gameList: this._games.gameListMapper(tournament.gameList) || [],
        gameListPreview: this._array.toChunks(this._games.gameListMapper(this._array.checkAndCloneArray(tournament.gameList ? tournament.gameList : [], tournament?.gameList?.length > 10 ? groupGameSlideCount : 1)), groupGameSlideCount),
        active: this.isActive(status),
        upcoming: this.isUpcoming(status),
        closed: this.isClosed(status),
        isLiveCasino: tournament.isLiveCasino
      };
    });
  }

  /**
   * Returns tournament status from provided dates
   *
   * @param start
   * @param end
   * @private
   */
  private _resolveTournamentStatus(start: Date, end: Date): TimeStatus {
    const currentDate = new Date();

    if (currentDate < start) {
      return TimeStatus.UPCOMING;
    } else if (currentDate > end) {
      return TimeStatus.CLOSED;
    } else {
      return TimeStatus.ACTIVE;
    }
  }

  /**
   * Prepare tournament response from SS for using in frontend
   *
   * @param tournament
   * @param userStatus
   * @private
   */
  private _mapSsTournament(tournament: any, userStatus): TournamentSS {
    if (userStatus) {
      userStatus.value = this._winnerStrategyValue(userStatus, tournament.strategy);
    }
    return {
      ...tournament,
      userStatus,
      top_players: this._mapTopPlayers(tournament.top_players, tournament.strategy),
      currency_symbol: this._data.currencySymbol(tournament.currency),
      mappedPrizes: this._mapPrizesList(tournament.prizes || [])
    };
  }

  /**
   * Add player value depends on tournament strategy
   *
   * @param topPlayers
   * @param strategy
   * @private
   */
  private _mapTopPlayers(topPlayers, strategy) {
    return (topPlayers || []).map(player => {
      player.value = this._winnerStrategyValue(player, strategy);
      return player;
    });
  }

  /**
   * Combine places with same prizes
   *
   * @param list
   * @private
   */
  private _mapPrizesList(list: any[]): any[] {
    const result = [];

    for (let i = 0; i < list.length; i++) {
      const wantedKey = (list[i].freespins_count || 0).toString() + list[i].money_award;
      const wantedIndex = list.lastIndexOf(list.slice().reverse().find(item => {

        return wantedKey === ((item.freespins_count || 0).toString() + item.money_award);
      }));

      const prize = {
        ...list[i],
        place: wantedIndex !== i ? list[i].award_place + '-' + list[wantedIndex].award_place : list[i].award_place,
        place_suffix: wantedIndex !== i ? '' : ['st', 'nd', 'rd'][((list[i].award_place + 90) % 100 - 10) % 10 - 1] || 'th',
        multiple: wantedIndex !== i
      };

      result.push(prize);

      if (wantedIndex > i) { i = wantedIndex; }
    }

    return result;
  }

  /**
   * Is Tournament Active
   */
  public isActive(status) {
    return status === TimeStatus.ACTIVE;
  }

  /**
   * Is Tournament Upcoming
   */
  public isUpcoming(status) {
    return status === TimeStatus.UPCOMING;
  }

  /**
   * Is Tournament Closed
   */
  public isClosed(status) {
    return status === TimeStatus.CLOSED;
  }

  /**
   * Return plater value depends on strategy
   * @param winner
   * @param strategy
   * @private
   */
  private _winnerStrategyValue(winner, strategy) {
    switch (strategy) {
      case 'bet':
        return this.roundWinsBets(winner.bets / 100);
      case 'win':
        return this.roundWinsBets(winner.wins / 100);
      case 'rate':
        return winner.rate.toFixed(2);
      case 'spin':
        return winner.games_taken.toFixed(2);
      case 'points':
        return winner.points.toFixed(2);
    }
  }

  roundWinsBets(x) {
    if (x >= 10) {
      return Math.floor(x);
    } else if (x >= 1 && x < 10) {
      return parseFloat(x).toFixed(1);
    } else {
      return parseFloat(x).toFixed(2);
    }
  }

  /**
   * Get tournament data and user tournament status from SS
   *
   * @param tournamentId
   * @private
   */
  private _getSSTournamentData(tournamentId) {
    return forkJoin([
      this._ssApi.tournaments(tournamentId),
      this._user.auth ? this._ssApi.playerTournamentStatus(tournamentId) : of(null)
    ]).pipe(map(([tournamentData, userStatus]) => this._mapSsTournament(tournamentData, userStatus)));
  }
}
