import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SideNavService } from '../side-nav/side-nav.service';
import { UserService } from '../../../services/user/user.service';
import { LanguageService } from '../../../services/language/language.service';
import { SeoService } from '../../../services/seo.service';
import { EnvironmentService } from '../../../services/environment.service';
import { GlobalEventsService } from '../../../services/global-events.service';
import { untilDestroyed } from 'ngx-unificator/rxjs';
import { UntilDestroy } from 'ngx-unificator/decorator';
import { filter, tap } from 'rxjs/operators';
import {PlatformService} from '../../../services/platform.service';
import {WindowService} from '../../../services/window.service';
import {Router} from '@angular/router';
import { HeaderNotAuthorizedComponent } from './header-not-authorized/header-not-authorized.component';
import { HeaderAuthorizedComponent } from './header-authorized/header-authorized.component';
import { NotificationCenterDropDownComponent } from '../../../modules/notification-center/notification-center-drop-down/notification-center-drop-down.component';
import { AppShellNoSSRDirective } from '../../directives/app-shell-no-ssr.directive';
import {NgStyle} from '@angular/common';
import {isMobile} from '../../../helpers/device';
import { XmasService } from '../../../../page/promo/xmas/xmas.service';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
  imports: [NgStyle, AppShellNoSSRDirective, NotificationCenterDropDownComponent, HeaderAuthorizedComponent, HeaderNotAuthorizedComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {

  /**
   * Not reload page for these links
   * @private
   */
  private LINKS_NOT_REQUIRE_PAGE_RELOAD = ['/mobile-app'];

  /**
   * For disable transparent if scroll down
   */
  @Input() colorDuringSroll: string;

  /**
   * For toggle colors
   */
  public changedColor: string;

  constructor(
    public user: UserService,
    public sidenav: SideNavService,
    public seo: SeoService,
    public lang: LanguageService,
    public env: EnvironmentService,
    public xmas: XmasService,
    private _events: GlobalEventsService,
    private _platform: PlatformService,
    private _window: WindowService,
    private _router: Router
  ) {
  }

  ngOnInit() {
    this.changedColor = '';
    this._events.scroll$.pipe(
      untilDestroyed(this),
      filter(() => window && !!this.colorDuringSroll),
      tap(() => {
        if (window.pageYOffset > 15) {
          this.changedColor = this.colorDuringSroll;
        } else {
          this.changedColor = '';
        }
      })
    ).subscribe();
  }

  ngOnDestroy() {
  }

  /**
   * to main page after click logo
   * @param $event
   */
  public toMainPage($event) {
    if (this._platform.isBrowser) {
      $event.preventDefault();
      if (this.LINKS_NOT_REQUIRE_PAGE_RELOAD.some(l => this._window.nativeWindow?.location?.href?.includes(l))) {
        return this._router.navigateByUrl('/').then();
      }

      this._window.nativeWindow.location.href = '/';
    }
  }

  protected readonly isMobile = isMobile;
}
