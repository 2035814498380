import { Injectable } from '@angular/core';
import { SsApiService } from '../api/ss-api.service';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {catchError, filter, map, share, switchMap, tap} from 'rxjs/operators';
import {
  BONUS_TYPES,
  BonusCodeStatus,
  BonusStage,
  BonusStageLabel,
  BonusStageLabelKeys, CLAIMED_WELCOME_4_SETUP, CLAIMED_WELCOME_BONUSES_GROUP
} from './data/user-bonuses.data';
import { CommonDataService } from '../common-data.service';
import { GamesService } from '../games/games.service';
import { FiltersService } from '../filters.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { GroupsService } from "../groups.service";
import { UserService } from "./user.service";
import { OffersService } from '../../../page/offers/offers.service';
import {isNullOrUndefined} from '../../helpers/utils';
import {TimeService} from '../time.service';
import {LootboxService} from '../lootbox/lootbox.service';
import {StaticContentService} from '../static-content.service';
import {BonusType} from '../../shared/types/bonus-type';
import { xmasStore } from '../../store/xmas.store';

export const DEFAULT_PATH = '/assets/img/bonuses/account';
export const DEFAULT_IMAGE_PATH = '/assets/img/bonuses/account/default_bonus.png';

const ListIds = {
  [`${DEFAULT_PATH}/first_deposit_bonus.png`]: ['first_deposit_bonus', 'deposit_fi_4'],
  [`${DEFAULT_PATH}/second_deposit_bonus.png`]: ['second_deposit_bonus', 'deposit_fi_5'],
  [`${DEFAULT_PATH}/third_deposit_bonus.png`]: ['third_deposit_bonus', 'deposit_fi_6'],
};

@Injectable({
  providedIn: 'root'
})
export class UserBonusesService {

  /**
   * Bonus code activation response success messages
   */
  private _couponSuccessMessages = {
    [BonusCodeStatus.SUCCESSFULLY_ACTIVATED]: 't.bonus-success-activated'
  };

  /**
   * Bonus code activation response error messages
   */
  private _couponErrorMessages = {
    [BonusCodeStatus.ALREADY_ACTIVATED]: 't.bonus-already-activated',
    [BonusCodeStatus.FAILED_TO_ACTIVATE]: 't.failed-active-bonus'
  };

  /**
   * Subject for update bonus code
   * @private
   */
  private _updateBonusCode$ = new Subject();

  /**
   * Subject for update bonuses
   * @private
   */
  private _updateBonuses$: Subject<boolean> = new Subject<boolean>();

  /**
   * Variable that keep boolean if user activated all welcome bonuses
   */
  private _isAllWelcomeBonusesActivated = false;

  /**
   * Cms bonus list
   * @private
   */
  private _cmsBonusList$ = this._offers.list({
    category_slug: 'welcome-bonuses'
  }).pipe(
    filter((data) => {
      return data?.length;
    }),
  );

  /**
   * Order for segment sort stage bonus
   * @private
   */
  private _orderBonusStage: string[] = [BonusStage.ACTIVATED, BonusStage.HANDLE_BETS, BonusStage.ISSUED, BonusStage.EXPIRED];

  public GAMES_INFO_LIMIT = 100;

  public xmasActivatedBonusesFs$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private _api: SsApiService,
    private _data: CommonDataService,
    private _games: GamesService,
    private _filters: FiltersService,
    private _toastMessage: ToastMessageService,
    private _user: UserService,
    private _group: GroupsService,
    private _offers: OffersService,
    private _time: TimeService,
    private _lootbox: LootboxService,
    private _static: StaticContentService
  ) {
  }

  get updateBonuses$() {
    return this._updateBonuses$;
  }

  get Stage() {
    return BonusStage;
  }

  get Type() {
    return BonusType;
  }

  get updateBonusCode$() {
    return this._updateBonusCode$;
  }

  /**
   * Return true if all welcome bonuses activated
   */
  get isAllWelcomeBonusesActivated() {
    return this._isAllWelcomeBonusesActivated;
  }

  get cmsBonusList$() {
    return this._cmsBonusList$;
  }

  /**
   * Returns list of player money bonuses
   */
  bonusList(): Observable<any> {
    return this._api.playerBonuses().pipe(
      catchError(() => of([])),
      map(list => this._mapPlayerBonuses(this._mapBonuses(list)))
    );
  }

  /**
   * Returns list of player free spins bonuses
   */
  freeSpinsList(): Observable<any> {
    const games = new Set();
    const bonuses = [];

    return this._api.playerFreeSpins().pipe(
      catchError(() => of([])),
      map(list => this._mapFreeSpins(this._mapBonuses(list))),
      tap(list => {
        list.forEach(bonus => bonus.games.forEach(game => games.add(game)));
        bonuses.push(...list);
      }),
      switchMap(() => this._games.list({
        'external_id[]': [...games]
      })),
      filter(response => !!response),
      map(gameResponse => {
        const gamesByExternalId = this._filters.valueAsKeyObject('externalId', gameResponse.gameList || []);

        const bonusList = bonuses.map(bonus => ({
          ...bonus,
          games: (bonus.games || []).map(gameId => gamesByExternalId[gameId] || {})
        }));

        return bonusList.map(bonus => ({
          ...bonus,
          frontend_image: this._resolveBonusImage(bonus)
        }));
      })
    );
  }

  /**
   * Send request on update bonus settings
   */
  changeIssues(state: boolean) {
    return this._api.playerUpdateBonusSettings({can_issue: state});
  }

  /**
   * Activate bonus by id
   *
   * @param id
   */
  activateBonus(id: string): Observable<any> {
    return this._api.playerBonusesActivation(id);
  }

  /**
   * Cancel bonus by id
   *
   * @param id
   */
  cancelBonus(id: string): Observable<any> {
    return this._api.playerBonusesCanceling(id);
  }

  /**
   * Activate free spins bonus by id
   *
   * @param id
   */
  activateFreeSpins(id: string | number): Observable<any> {
    return this._api.playerFreeSpinsActivation(id);
  }

  /**
   * Cancel bonus by id
   *
   * @param id
   */
  cancelFreeSpins(id: string | number): Observable<any> {
    return this._api.playerFreeSpinsCanceling(id);
  }

  /**
   * Prepare bonus list for using in frontend
   *
   * @param list
   * @private
   */
  private _mapBonuses(list: Array<any>): Array<any> {
    return list.map(bonus => ({
      ...bonus,
      isWelcome: this._isWelcomeBonus(bonus.title),
      title: this.mapBonusTitle(bonus.title),
      active: ![BonusStage.LOST, BonusStage.CANCELED, BonusStage.PLAYED, BonusStage.FINISHED, BonusStage.EXPIRED].includes(bonus.stage),
      stage_label: BonusStageLabel[bonus.stage] || bonus.stage,
      created_at: bonus.created_at ? new Date(bonus.created_at) : null,
      activatable_until: bonus.activatable_until ? new Date(bonus.activatable_until) : null,
      valid_until: bonus.valid_until ? new Date(bonus.valid_until) : null,
      frontend_image: this._resolveBonusImage(bonus)
    }));
  }

  /**
   * Prepare player bonuses for using if frontend
   *
   * @param list
   * @private
   */
  private _mapPlayerBonuses(list: Array<any>): Array<any> {
    return list.map(bonus => ({
      ...bonus,
      currency_symbol: this._data.currencySymbol(bonus.currency),
      amount: this._data.subunitsToUnits(bonus.amount_cents, bonus.currency),
      amount_wager_requirements: this._data.subunitsToUnits(bonus.amount_wager_requirement_cents, bonus.currency),
      amount_wager: this._data.subunitsToUnits(bonus.amount_wager, bonus.currency),
      wager: (bonus.amount_wager_requirement_cents ?
        (bonus.amount_wager_cents / bonus.amount_wager_requirement_cents * 100) :
        100),
      type: BONUS_TYPES.CASH,
    }));
  }

  /**
   * Prepare free spins bonuses for using in frontend
   *
   * @param list
   * @private
   */
  private _mapFreeSpins(list: Array<any>): Array<any> {
    return list.map(bonus => ({
      ...bonus,
      freespins_performed: bonus.freespins_performed || 0,
      type: BONUS_TYPES.FREESPINS
    }));
  }

  /**
   * Bonus deposit code activation
   *
   * @param code
   */
  public activateDepositBonusCode(code): Observable<any> {
    return this._api.playerSetBonusCode({deposit_bonus_code: (code || '').toUpperCase()});
  }

  /**
   * Coupon code activation
   *
   * @param code
   */
  public activateCoupon(code: string): Observable<any> {
    return this._api.bonusesCoupon({coupon_code: (code || '').toUpperCase()});
  }

  /**
   * Bonus deposit code clear
   *
   */
  public deleteDepositBonusCode(): Observable<any> {
    return this._api.playerClearBonusCode();
  }

  /**
   * Show toast message depend on coupon code activation response
   *
   * @param response
   * @private
   */
  public resolveCouponResponseMessage(response) {
    const status = response['status'];

    if (this._couponSuccessMessages[status]) {
      this._toastMessage.success(this._couponSuccessMessages[status]);
    } else if (this._couponErrorMessages[status]) {
      this._toastMessage.error(this._couponErrorMessages[status]);
    } else {
      this._toastMessage.error('t.undefined-error');
    }
  }

  /**
   * Returns list of bonuses that will be activated on next deposit
   */
  depositBonusList(): Observable<any> {
    return this._api.bonusesDeposit().pipe(
      map(list => {
        return (list || []).map(bonus => {

          const preparedBonus = {};

          bonus.bonuses.forEach(bonusPart => {
            preparedBonus[bonusPart.type] = {
              id: bonus.id,
              title: this.mapBonusTitle(bonusPart.title),
              attributes: this.resolveBonusAttributes(bonusPart.attributes, preparedBonus),
              conditions: this.resolveBonusAttributes(bonusPart.conditions)
            };
          });

          return preparedBonus;
        });
      }),
      map(list => list.map(bonus => {
        return {
          ...bonus,
          frontend_image: this._resolveBonusImage(bonus)
        }
      })),
      catchError(() => of([])),
    );
  }

  /**
   * Returns object from array of attributes
   *
   * @param list
   * @param preparedBonus
   */
  public resolveBonusAttributes(list: Array<any>, preparedBonus: any = {}) {
    const attributes: any = {};

    list.forEach(attribute => {
      if (attribute.field === 'bonus_amount' && attribute.type === 'max') {
        attributes.bonus_amount_max = this._resolveAmountList(attribute.value);
      } else if (attribute.field === 'bonus_amount' && attribute.value.percent) {
        attributes.bonus_amount_percent = attribute.value.percent;
      } else if (attribute.field === 'amount' && attribute.type === 'min') {
        attributes.amount_min = this._resolveAmountList(attribute.value);
      } else if (attribute.field === 'amount' && attribute.type === 'max') {
        attributes.amount_max = this._resolveAmountList(attribute.value);
      } else if (attribute.field === 'freespins_count') {
        attributes.freespins_count = this._resolveFreeSpinsValue(
          preparedBonus &&
          preparedBonus.freespins &&
          preparedBonus.freespins.attributes &&
          preparedBonus.freespins.attributes.freespins_count  || 0, attribute.value, list
        );
      } else {
        attributes[attribute.field] = attribute.value;
      }
    });

    return attributes;
  }

  /**
   * Resolve  free spins value
   * @param previousFreeSpinsValue
   * @param currentFreeSpinsValue
   * @param attrList
   * @private
   */
  private _resolveFreeSpinsValue(previousFreeSpinsValue: number, currentFreeSpinsValue: number, attrList) {
    const cyclesAttr = attrList.find(attr => attr.field === 'cycles');
    const cyclesValue = Number(cyclesAttr && cyclesAttr.value || 1);

    return previousFreeSpinsValue + cyclesValue * currentFreeSpinsValue;
  }

  /**
   * Convert amounts list from backend to object where keys is currency and values is converted amount
   *
   * @param list
   * @private
   */
  private _resolveAmountList(list: Array<any>) {
    const amounts = {};

    list.forEach(amount => {
      amounts[amount.currency] = this._data.subunitsToUnits(amount.amount_cents, amount.currency);
    });

    return amounts;
  }

  /**
   * Prepare bonus title for using in frontend
   *
   * @private
   */
  public mapBonusTitle(title: string): string {
    return (title || '')
      .replace(/\[(.*?)]/g, '')
      .replace(/\[(.*?)}/g, '')
      .replace(/\{(.*?)]/g, '')
      .replace(/\{(.*?)}/g, '');
  }

  /**
   * Compare bonus list and return active bonus
   * @param bonusListCMS
   * @param bonusListSS
   * @returns
   */
  public findActiveBonusCMSIndex(bonusListCMS: any[], bonusListSS: any[]) {
    if (this._user.auth && this._resolveIsAllWelcomeUsed()) {
      this._isAllWelcomeBonusesActivated = true;
    }
    if (bonusListCMS && bonusListSS) {
      return bonusListCMS.findIndex(cmsBonus => {
        const BOIdentifier = cmsBonus.BOIdentifier && cmsBonus.BOIdentifier.toLowerCase();
        return bonusListSS.find(ssBonus => {
          return ssBonus && ssBonus.bonus && ssBonus.bonus.id && ssBonus.bonus.id.toLowerCase().includes(BOIdentifier) ||
            ssBonus && ssBonus.freespins && ssBonus.freespins.id && ssBonus.freespins.id.toLowerCase().includes(BOIdentifier);
        });
      });
    }
  }

  /**
   * Resolve images for bonuses
   * @private
   */
  private _resolveBonusImage(bonus) {
    if (bonus.bonus && typeof bonus.bonus.id === 'string') {
      const {id: bonusId} = bonus.bonus;

      const pathKey = Object.entries(ListIds).find((values) => values[1].some(id => bonusId.includes(id)));

      if (pathKey && pathKey[0]) return pathKey[0];
      else return DEFAULT_IMAGE_PATH;
    } else {
      if (bonus.games && bonus.games.length && bonus.games[0].imgSrc) {
        return bonus.games[0].imgSrc;
      } else if (bonus.bonus) {
        return `/assets/img/bonuses/account/${bonus.bonus.id}.png`;
      } else {
        return DEFAULT_IMAGE_PATH;
      }
    }
  }

  /**
   * Check is bonus FS
   *
   */
  public isFSBonus(bonus) {
    return bonus.activation_path && bonus.activation_path.includes('freespins');
  }

  /**
   * Check is bonus lootbox
   *
   */
  public isLootbox(bonus) {
    return bonus.strategy === 'lootbox_item' && bonus.stage === 'issued';
  }

  private _resolveIsAllWelcomeUsed() {
    return this._group.isExistGroup(CLAIMED_WELCOME_BONUSES_GROUP)
      || this._group.isExistGroup(CLAIMED_WELCOME_4_SETUP);
  }

  /**
   * Compare bonus list and return active bonus
   * @param bonusListCMS
   * @param bonusListSS
   * @returns
   */
  private _findActiveBonus(bonusListCMS: any[], bonusListSS: any[]) {
    return bonusListCMS.find(cmsBonus => {
      return bonusListSS.find(ssBonus => {
        if (ssBonus?.id?.includes(cmsBonus.BOIdentifier)
          || ssBonus?.bonus?.id?.includes(cmsBonus.BOIdentifier)
          || ssBonus?.bonus?.freespins?.id?.includes(cmsBonus.BOIdentifier)
          || ssBonus?.freespins?.id?.includes(cmsBonus.BOIdentifier)
        ) {
          return ssBonus;
        } else {
          return null;
        }
      });
    });
  }


  /**
   * Mark active bonus and resolve active bonus image
   * @param bonusListCMS
   * @param bonusListSS
   * @private
   */
  public markActiveBonus(bonusListCMS: any[], bonusListSS: any[]) {
    if (this._user.auth) {
      const isAllWelcomeUsed = this._resolveIsAllWelcomeUsed();
      if (isAllWelcomeUsed) {
        bonusListCMS.forEach(e => {
          e.used = true;
        });
        this._isAllWelcomeBonusesActivated = true;
      } else {
        let activeBonus = this._findActiveBonus(bonusListCMS, bonusListSS);
        if (!activeBonus) {
          activeBonus = bonusListCMS[0];
        }
        const activeBonusIndex = bonusListCMS.findIndex(e => e.id === activeBonus.id);
        bonusListCMS.forEach((e, i) => {
          if (i < activeBonusIndex) {
            e.used = true;
          }
        });
        if (activeBonus) {
         activeBonus.active = true;
        }
      }
      return bonusListCMS;
    } else {
      bonusListCMS[0].active = true;
      return bonusListCMS;
    }

  }

  /**
   * Resolve bonus label for bonus
   * @param stage
   * @private
   */
  private _resolveBonusLabel(stage) {
    return BonusStageLabelKeys[stage];
  }

  private _resolveGamesInfo(gamesInfo: any[]) {
    return gamesInfo && gamesInfo.length > this.GAMES_INFO_LIMIT ? gamesInfo.slice(1, this.GAMES_INFO_LIMIT + 1) :
      gamesInfo && gamesInfo.length < this.GAMES_INFO_LIMIT ? gamesInfo : null;
  }

  public getAllBonuses() {
    return combineLatest([this.depositBonusList(), this.freeSpinsList(), this.bonusList(), this._lootbox.lootIssuedboxList$]).pipe(
      map(([deposit, fs, bonuses, lootboxes]) => {
        return deposit.concat(fs).concat(bonuses).concat(lootboxes);
      }),
      map(list =>
        list.filter(
          bonus =>
            bonus.active !== false &&
            bonus.stage !== this.Stage.EXPIRED &&
            bonus.stage !== this.Stage.FINISHED &&
            bonus.stage !== this.Stage.CANCELED
        ),
      ),
      map(list =>
        list.map(item => {
          const stage = item.bonus ? BonusStage.ISSUED : item.stage;
          return {
            ...item,
            endAt: !isNullOrUndefined(item.valid_until) ? this._time.timeDiff(new Date(item.valid_until)) : null,
            stage,
            imgSrc: this._resolveBonusImage(item),
            frontend_label: this._resolveBonusLabel(stage),
            games_info: this._resolveGamesInfo(item.games_info)?.map((game, i) => i === 0 ? this._games.getGameByExternalId(game?.identifier).pipe(share()) : game),
            useAndSymbolInTemplate: item.games_info && item.games_info.length > this.GAMES_INFO_LIMIT
          };
        }),
      ),
      map(list => list.filter(item => item && item.stage)),
      map(list => this._filters.orderBySegment(list, this._orderBonusStage, 'stage')),
      tap((list) => {
        this.xmasActivatedBonusesFs$.next(list.filter(
          (e) => (e.stage === this.Stage.ACTIVATED || e.stage === this.Stage.ISSUED) &&
            (e.type === this.Type.FREE_SPINS) && (e.title.trim().toLowerCase().includes(xmasStore.bonusTitleString) &&
              this._time.isTodayDate(e.created_at)
            )))
      }),

      switchMap(list => combineLatest(of(list), !this._user?.info?.confirmed_at ? this._static.item({slug: BONUS_TYPES.NDFS}) : of([]))),
      map(([list, ndfs]) => {
        return (ndfs?.length ? [{type: BONUS_TYPES.NDFS, frontend_label: BonusStageLabelKeys[BonusStage.AVAILABLE], stage: BonusStage.AVAILABLE}] : [])?.concat(list);
      })
    );
  }

  public checkDifferenceMoreTwoDays() {
    if (!this._user.info.created_at) {
      return true;
    } else {
      const today = new Date();
      // ios problem handling
      const dateString = this._user.info.created_at.replace(' ', 'T').replace('UTC', '').trim() + 'Z';
      const dateRegister = new Date(dateString);
      const millisecondsDiff = today.getTime() - dateRegister.getTime();
      return Math.round(
        millisecondsDiff / (24 * 60 * 60 * 60),
      ) >= 2;
    }
  }

  /**
   * Checks if title contains WCM or AFF which indicates that it's a welcome bonus
   *
   * @param title
   * @private
   */
  private _isWelcomeBonus(title: string) {
    return ['WLC', 'WCM', 'AFF', 'Welcome'].some(key => title.toUpperCase().includes(key));
  }

  /**
   * Get bonuses for bets history
   */
  public getBonusesBetsHistory() {
    return combineLatest([this.freeSpinsList(), this.bonusList(), this._lootbox.lootIssuedboxList$]).pipe(
      map(([fs, bonuses, lootboxes]) => fs.concat(bonuses).concat(lootboxes)),
      map(list => list.map(item => ({ ...item, gamesHistory: item?.type === 'freespins'? item?.games : [] }))),
      map(list => list.filter(item => item && item.stage)),
      map(list => this._filters.orderBySegment(list, this._orderBonusStage, 'stage')),
    );
  }
}
