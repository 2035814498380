import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

export interface ClickOutsideOptions {
  /**
   * clickOutside event will not be emitted if clicked element inside one of elements with provided selectors
   */
  ignoreOutsideComponents: Array<string>;
}

@Directive({
    selector: '[ClickOutside]',
    standalone: true
})
export class ClickOutsideDirective {

  @Input('options') options: ClickOutsideOptions = {
    ignoreOutsideComponents: []
  };

  constructor(
    private _elementRef: ElementRef,
  ) {
  }

  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();
  @Output('clickInside') clickInside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:mousedown', ['$event.target']) clickedOutside(target) {
    if (this._isClickedInside(target)) {
      this.clickInside.emit(target);
    } else {
      this.clickOutside.emit(target);
    }
  }

  /**
   * Check if a click was inside the element
   *
   * @param target
   * @private
   */
  private _isClickedInside(target: HTMLElement): boolean {
    if (this._elementRef.nativeElement.contains(target)) {
      return true;
    } else {
      return this._isIgnoreOutsideElementsClicked(target);
    }
  }

  /**
   * Check if clicked element inside ignored elements
   *
   * @param target
   * @private
   */
  private _isIgnoreOutsideElementsClicked(target: HTMLElement): boolean {
    return this.options.ignoreOutsideComponents.some((selector) => target.closest(selector));
  }

}
