import { Injectable } from '@angular/core';
import {ScriptLoaderService} from '../script-loader.service';
import { catchError, tap, filter, map, switchMap, delay, distinctUntilChanged } from 'rxjs/operators';
import { of, ReplaySubject} from 'rxjs';
import {UserService} from '../user/user.service';
import {LanguageService} from '../language/language.service';
import {PlatformService} from '../platform.service';
import { CookieService } from 'ngx-unificator/services';
import {StaticContentService} from '../static-content.service';
import {
  VIP_CHAT_EXPIRE_AT_COOKIE,
  ZENDESK_SCRIPT_URL,
} from './zendesk-chat-data';
import { environment } from '../../../../environments/environment';

declare var zE;

@Injectable({
  providedIn: 'root'
})

export class ZendeskChatService {

  /**
   * Returns true if zendesk chat available
   */
  private _available: boolean;

  /**
   * Emits true if zendesk chat available
   */
  private _available$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  /**
   * Is vip chat set up now
   */
  public isVipChatNow: boolean;

  /**
   * Returns true if chat open
   * @private
   */
  private _isOpen: boolean;

  /**
   * Timeout to restore default chat (remove vip)
   */
  private _timeoutToRemoveVipChat: any;

  /**
   * Time to restore default chat after closing vip chat
   */
  private readonly _timeToRestoreDefaultChat = 20 * 60 * 1000;

  /**
   * Origin href favicon
   * @private
   */
  private _originHrefFavicon: string

  constructor(
    private _scriptLoader: ScriptLoaderService,
    private _user: UserService,
    private _lang: LanguageService,
    private _platform: PlatformService,
    private _cookie: CookieService,
    private _static: StaticContentService,
  ) {
  }

  /**
   * Success from outside
   */
  get available() {
    return this._available;
  }

  get available$() {
    return this._available$;
  }

  get isOpen() {
    return this._isOpen;
  }

  /**
   * Get widget api key
   * @private
   */
  private _getWidgetApiKey$() {
    return this._static.item({slug: 'zendesk-api-key'}).pipe(
      filter(data => !!data && data[0]),
      map(data => data[0].Content && data[0].Content.trim())
    );
  }

  /**
   * Init zendesk chat
   */
  public initChat(forVip: boolean = false) {
    if (this._platform.isBrowser) {
      this._user.auth$.pipe(
        distinctUntilChanged(),
        switchMap(() => this._getWidgetApiKey$()),
        filter(key => !!key),
        tap(() => {
          const zeScript = document.querySelector('#ze-snippet');
          if (zeScript) {
            zeScript.remove();
          }
          this._available = false;
          this._available$.next(false);
        }),
        delay(0),
        switchMap(key => this._scriptLoader.load(`${ZENDESK_SCRIPT_URL}?key=${key}&timestamp=${Date.now()}`, undefined, {id: 'ze-snippet'})),
        filter(() => Boolean(zE)),
        tap(() => {
          this._available = true;
          this._available$.next(true);
          this._setInitFunctionalData(forVip);
          zE('messenger', 'close');
        }),
        catchError(error => {
          this._available = false;
          this._available$.next(false);
          return of(error);
        })
      ).subscribe();
    }
  }

  /**
   * Set init data for functional chat
   * @private
   */
  private _setInitFunctionalData(isVip: boolean = false) {
    this.hide();

    if (this._user.auth) {
      this.setUserData();
    }
    this._onClose();
    this._onChangeLanguage();

    this._onMessage();
    this._addTags([this._lang.current ? this._lang.current : '',
      this._cookie.get('id_id') ? this._cookie.get('id_id') : '']);
  }

  /**
   * Toggle chat
   * @open
   */
  public toggle() {
    this.isOpen ? this.hide() : this.open();
  }

  /**
   * Hide chat
   */
  public hide() {
    if (this._platform.isBrowser) {
      zE('messenger', 'close');
    }
  }

  /**
   * Open chat
   */
  public open() {
    if (this._platform.isBrowser) {
      zE('messenger', 'open');
    }
  }

  /**
   * Handler on close widget
   * @private
   */
  private _onClose() {
    if (this._platform.isBrowser) {
      zE('messenger:on', 'close', () => {
        this._isOpen = false;
        this._removeUnreadDotFromFavicon();

        if (this.isVipChatNow) {
          if (!this._timeoutToRemoveVipChat) {
            this._restoreDefaultChatIn(this._timeToRestoreDefaultChat);
            this._cookie.set(VIP_CHAT_EXPIRE_AT_COOKIE, (Date.now() + this._timeToRestoreDefaultChat).toString(), new Date(Date.now() + this._timeToRestoreDefaultChat), '/', (<any>window.location.hostname));
          }
        }
      });
    }
  }

  /**
   * Set locale for chat
   * @private
   * @locale
   */
  private _setLocale(locale) {
    if (this._platform.isBrowser) {
      if (this.available) {
        zE('messenger:set', 'locale', locale);
      }
    }
  }

  /**
   * Add tags
   * @private
   * @tags
   */
  private _addTags(tags: string[]) {
    if (this._platform.isBrowser) {
      zE("messenger:set", "conversationTags", tags);
    }
  }

  /**
   * Change locale for chat
   * @private
   */
  private _onChangeLanguage() {
    this._lang.langChange$.pipe(
      tap(locale => this._setLocale(locale))
    ).subscribe();
  }

  /**
   * Open chat if message coming
   * @private
   */
  private _onMessage() {
    if (this._platform.isBrowser && this.available && zE) {
      zE('messenger:on', 'unreadMessages', countMessage => {
        if (!this._isOpen && countMessage > 0) {
          this.open();
          this._addUnreadDotToFavicon();
        } else {
          this._removeUnreadDotFromFavicon();
        }
      });
    }

  }

  /**
   * Restore default chat in passed ms
   */
  private _restoreDefaultChatIn(ms: number) {
    this._timeoutToRemoveVipChat = setTimeout(() => {
      this.initChat(false);
    }, ms);
  }

  /**
   * Set user data (name, email)
   * @param data
   * @public
   */
  public setUserData() {
    const userObj = {
      user_id: `${this._user.info.id}`,
      first_name: this._user.info.first_name,
      last_name: this._user.info.last_name,
      date_of_birth: this._user.info.date_of_birth,
      email: this._user.info.email
    };
    if (Object.values(userObj).every((e) => !!e)) {
      zE('messenger', 'loginUser', (callback) => {
        fetch(`${environment.zendesk_host}/auth`, {method: 'POST', body: JSON.stringify(userObj)}).then((res) => {
          res.json().then((jwt) => {
            callback(jwt.token);
          });
        });
      });
    }
  }


  private _updateFavicon(favicon: HTMLLinkElement, newFaviconUrl: string) {
    favicon.href = newFaviconUrl;
  }
  private _addUnreadDotToFavicon() {
    if (this._platform.isBrowser) {
      const favicons = this._getFavicons();

      favicons.forEach((favicon) => {
        const originalFavicon = favicon.href;
        if (!originalFavicon) return;

        if (!this._originHrefFavicon) { this._originHrefFavicon = originalFavicon; }

        const canvas = document.createElement('canvas');
        const img = new Image();
        img.src = originalFavicon;
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;

          const context = canvas.getContext('2d');
          if (context) {
            context.drawImage(img, 0, 0);

            // Draw the red dot
            const dotSize = Math.max(4, img.width / 4); // Adjust dot size dynamically
            const xPos = canvas.width - dotSize;
            const yPos = dotSize;

            context.beginPath();
            context.arc(xPos, yPos, dotSize, 0, 2 * Math.PI);
            context.fillStyle = 'red';
            context.fill();

            this._updateFavicon(favicon, canvas.toDataURL('image/png'));
          }
        };
      });
    }
  }

  private _removeUnreadDotFromFavicon() {
    const favicons = this._getFavicons();
    favicons.forEach((favicon) => {
      if (this._originHrefFavicon) {
        favicon.href = this._originHrefFavicon;
      }
    });
  }

  private _getFavicons(): NodeListOf<HTMLLinkElement> {
    return document.querySelectorAll('link[rel*="icon"]');
  }
}

