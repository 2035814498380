<header class="header" [ngStyle]="{backgroundColor: changedColor}">
  <div class="container">
    <div class="content-container">
      <div class="left">
        <div class="menu">
          <img src="../../../../../assets/svg/burger.svg" alt="" (click)="sidenav.toggle()">
          <div class="notify">
            <app-notification-center-drop-down *appShellNoSSR></app-notification-center-drop-down>
          </div>
        </div>
      </div>
      <div class="center">
        <a [href]="seo.originUrl + '/' + lang.current" class="logo" title="Wildfortune" (click)="toMainPage($event)">
          @if (env.isSe || !xmas.isPromoLogo) {
            <img src="/assets/svg/logo.svg" alt="Wildfortune logo">
          }

          @if (!env.isSe && xmas.isPromoLogo) {
            @if (isMobile()) {
              <img src="/assets/svg/xmas-logo-mobile.svg" alt="Wildfortune logo">
            } @else {
              <img src="/assets/svg/xmas-logo.svg" alt="Wildfortune logo">
            }
          }
          <span>Wildfortune</span>
        </a>
      </div>
      <div class="right">
        <ng-container>
          @if (user.auth) {
            <app-header-authorized></app-header-authorized>
          }
          @if (!user.auth) {
            <app-header-not-authorized></app-header-not-authorized>
          }
        </ng-container>
      </div>
    </div>
  </div>
</header>
