import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { StaticPageService } from '../../page/static-page/static-page.service';
import { BonusShopPage } from '../shared/types/bonus-shop';

@Injectable({
  providedIn: 'root',
})
export class BonusStoreService {

  public isShowBonusStore = false;

  constructor(
    private _page: StaticPageService,
  ) {
  }

  public getStorePage$(): Observable<BonusShopPage> {
    return this._page.item({ slug: 'bonus-store' }).pipe(
      tap((response) => {
        this.isShowBonusStore = !response?.[0]?.statusCode
      }),
      filter(list => !!list?.length),
      map(response => {
        return response?.map(item => {
          return {
            ...item,
            Goods: [...Object.values(item?.Goods || {})],
            HowEarnSteps: [...Object.values(item?.HowEarnSteps || {})],
          };
        });
      }),
      map(list => list[0]),
      shareReplay(1)
    );
  }

  public transformPrizeData(data: any) {
    const result = [];
    // eslint-disable-next-line guard-for-in
    for (const key in data) {
      const prize = data[key];
      const title = key.replace(/Prize$/, '');
      const isLegendary = title.includes('Legendary');
      const isRare = title.includes('Rare');
      const isMythical = title.includes('Mythical');
      const isCommon = title.includes('Common');

      result.push({
        value: prize,
        isRare,
        isMythical,
        isCommon,
        isLegendary,
      });
    }

    return result.sort(this._compareLegendaryState);
  }

  public matchLootboxesWithImages(data) {
    data?.Goods?.forEach(good => {
      const lootboxIdParts = good?.LootboxId?.split('_')?.slice(0, 3);
      const bonusStoreKeyPattern = lootboxIdParts?.slice(1).map(part => part?.charAt(0)?.toUpperCase() + part?.slice(1))?.join('');
      const bonusStoreKey = Object.keys(data)?.find(key => key?.includes(bonusStoreKeyPattern));
      if (bonusStoreKey) {
        good.image = data?.[bonusStoreKey];
      }
    });
  }

  private _compareLegendaryState(a, b) {
    if (a.isLegendary && !b.isLegendary) {
      return -1;
    } else if (!a.isLegendary && b.isLegendary) {
      return 1;
    }
    return 0;
  }
}
